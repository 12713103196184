import styled from "@emotion/styled";
import mediaqueries from "@styles/media";

const Blockquote = styled.blockquote`
  transition: ${p => p.theme.colorModeTransition};
  margin: 15px auto 50px;
  width: 100%;
  max-width: 744px;
  color: ${p => p.theme.colors.articleText};
  font-family: ${p => p.theme.fonts.serif};
  font-style: italic;
  opacity: 0.7;

  ${mediaqueries.tablet`
    margin: 10px auto 35px;
    max-width: 526px;
    padding: 20px;
  `};

  & > p {
    position: relative;
    font-family: ${p => p.theme.fonts.serif};
    padding-left: 25px;
    width: 100%;
    margin: 0 auto;
    font-size: 20px;
    line-height: 1.62;
    font-weight: bold;

    &::before {
      display: block;
      position: absolute;
      content: '';
      top: 0;
      bottom: 0;
      left: 0;
      width: 5px;
      border-left: 5px solid ${p => p.theme.colors.horizontalRule};
    }
  }
`;

export default Blockquote;
