import React from "react";

import Section from "@components/Section";
import Headings from "@components/Headings";

import styled from "@emotion/styled";
import mediaqueries from "@styles/media";

const Newsletter = () => {
  return (
    <Section narrow>
      <SubscriptionContainer>
        <Content>
          <Heading>
            Join my email list and get notified about new content
          </Heading>
          <Text>
            At most once a week, most probably once a month. Also: unsubscribe at any time.
          </Text>
          <iframe
            scrolling="no"
            style={{
              width: "100%",
              height: "220px",
              border: "1px #ccc solid"
            }}
            src="https://buttondown.email/mjsarfatti?as_embed=true"
          />
        </Content>
      </SubscriptionContainer>
    </Section>
  );
};

export default Newsletter;

const SubscriptionContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 64px 0 55px;
  margin: 10px auto 100px;
  background: ${p => p.theme.colors.card};
  box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.05);
  z-index: 1;

  ${mediaqueries.tablet`
    padding: 50px 0 0;
    text-align: center;
  `}

  ${mediaqueries.phablet`
    margin: -20px auto 80px;
  `}
`;

const Content = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 640px;

  ${mediaqueries.tablet`
    h3 {
      padding: 0 50px;
    }
  `}

  ${mediaqueries.phone`
    h3 {
      padding: 0 24px;
    }
  `}
`;

const Heading = styled(Headings.h3)`
  margin-bottom: 20px;

  ${mediaqueries.tablet`
    margin-bottom: 15px;
  `}
`;

const Text = styled.p`
  margin: 0 auto 30px;
  color: ${p => p.theme.colors.grey};
  line-height: 1.75;

  ${mediaqueries.tablet`
    padding: 0 26px;
    margin: 0 auto 25px;
  `}
`;
